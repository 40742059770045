export enum SubscriptionGiftAvailability {
  AVAILABLE = "AVAILABLE",
  OUT_OF_STOCK = "OUT_OF_STOCK",
  INELIGIBLE_PROFILE = "INELIGIBLE_PROFILE",
  INELIGIBLE_CUSTOMER = "INELIGIBLE_CUSTOMER",
  NOT_RUNNING = "NOT_RUNNING"
}

export interface SubscriptionGiftAvailabilityResponse {
  ruleId: string,
  planIds: string[],
  giftDescription: string,
  availability: SubscriptionGiftAvailability
}

export default {
  async listAvaiableSubscriptionGiftPromotions(): Promise<SubscriptionGiftAvailabilityResponse[]> {
    return await waffleFetch(`promotions/subscription-gift/available`)
  },
  async getSubscriptionGiftAvailability(promotionId: string): Promise<SubscriptionGiftAvailabilityResponse> {
    return await waffleFetch(`promotions/subscription-gift/${promotionId}/availability`)
  }
}